.sidebar {
  width: 18%;
  height: 100vh;
  position: fixed;
  background-color: #fcfcfc;
  overflow-y: scroll;

  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .activeLink {
    background-color: #35abaf;
    margin: 8px 30px;
    border-radius: 12px;
  }

  .nonActiveLink {
    margin: 8px 30px;
    border-radius: 12px;
  }
}

.active_icon {
  font-size: 18px !important;
  color: #fff
}

.inactive_icon {
  font-size: 18px !important;
  color: #999999
}