.activeFilterSort {
    color: #35ABAF !important;
}

.searchBox {
   border: 1px solid #35abaf ; 
   outline: none;
}

.searchBtn{
    background-color: #35ABAF;
    border: 1px solid #35ABAF;
    padding: 5px;
}