@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Montserrat&display=swap');

body {
  margin: 0;
  font-family: "DM Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-white-background {
  background-color: #FCFCFC;
}

.heading-text-color {
  color: #515151;
}

.primary-btn {
  background-color: #35ABAF;
  color:white;
  border:2px solid;
}
.primary-btn:active {
  background-color: white;
  color:#35ABAF;
  border: 2px solid #35ABAF;
}
.secondary-btn {
  background-color: white;
  color: #35ABAF;
  border: 2px solid #35ABAF;
}
.secondary-btn:active {
  background-color: #35ABAF;
  color: white;
  border: 2px solid white;
}
tr:active{
  /* background-color: grey !important; */
  /* transition: all 0.5s; */
}

.toggle-btn{
  /* position: relative;
  z-index: 50; */
}
.toggle-btn .container {
  text-align: center;
}
.toggle-btn .toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.toggle-btn .checkbox {
  display: none;
}
.toggle-btn .label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.toggle-btn .inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-btn .inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-btn .inner:before {
  content: "";
  padding-left: 10px;
  background-color: #35ABAF;
  color: #fff;
}
.toggle-btn .inner:after {
  content: "";
  padding-right: 10px;
  background-color: #515151;
  color: #fff;
  text-align: right;
}
.toggle-btn .switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-btn .checkbox:checked + .label .inner {
  margin-left: 0;
}
.toggle-btn .checkbox:checked + .label .switch {
  right: 0px;
}
